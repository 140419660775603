<template>
  <v-row class="px-10" dense>
    <v-col rows="12" md="6">
      <v-text-field
        v-model="seller.mail_host"
        label="SMTP - Host"
        placeholder=""
        outlined
        rounded
        dense
      />
     
      <v-text-field
            v-model="seller.mail_port"
            label="Porta"
            placeholder=""
            outlined
            rounded
            dense
          />
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="seller.mail_username"
        label="Email"
        outlined
        rounded
        dense
      />
      <v-text-field
        v-model="seller.mail_password"
        label="Senha"
        type="tel"
        outlined
        rounded
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    seller: Object
  }
}
</script>